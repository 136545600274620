.backdrop {
  background-color: #fff;
  padding: 1rem;
  margin: 1rem 0rem;
  border-radius: 5px;
}

.form-element {
  margin-bottom: 1rem;
}

.form-element input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-element input:focus,
.form-element input:active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
