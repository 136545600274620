iframe {
  width: 100%;
  height: calc(100vh - 58px);
}

.gamePlayMenus {
  background-color: #2e3888;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
}

/* IMA Styling */

.adLoadingWrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: none;
  z-index: 99987;
  background-color: #000;
}

.adLoadingWrapper span {
  display: block;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#mainContainer {
  display: block;
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#adContainer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -ms-flex-item-align: center;
  align-self: center;
  vertical-align: middle;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #000;
  z-index: -100;
}

#contentElement {
  margin: 0 auto;
  background: #000;
  position: absolute;
  top: 20%;
  z-index: -10;
}

.nonLinearContainer {
  bottom: 10;
  height: 100%;
}

#playButton {
  margin-top: 10px;
  vertical-align: top;
  width: 350px;
  height: 60px;
  padding: 0;
  font-size: 22px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #2c3e50;
  border: 0;
  border-bottom: 2px solid #22303f;
  cursor: pointer;
  -webkit-box-shadow: inset 0 -2px #22303f;
  box-shadow: inset 0 -2px #22303f;
}
