.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0rem;
}

.about-us,
.about-us-text {
  color: #1febc6;
  font-size: 1.8rem;
  width: 90%;
  margin: 0 auto;
}

.about-us-text {
  color: #fff;
  font-size: 1.5rem;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-container img {
  max-width: 20rem;
}
