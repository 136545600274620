body {
  font-family: "Roboto", sans-serif;
  background-color: #091529 !important;
}

.header {
  background: #202f47;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.navbarItems {
  list-style: none;
}

.navarLinksItems {
  display: inline-block;
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}

.header-box {
  height: 60vh;
  background-image: linear-gradient(to right bottom, #8d6fd5, #28b485);
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
}

.sectionBg {
  background-image: linear-gradient(to right bottom, #8d6fd5, #28b485);
}

.logo-box {
  position: absolute;
  top: 30px;
  left: 30px;
}

.logo {
  height: 45px;
}

.text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.primary-heading {
  color: #fff;
  text-transform: uppercase;
}

.primary-heading-main {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 22px;
  display: block;
  -webkit-animation-name: moveToLeft;
          animation-name: moveToLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

.primary-heading-sub-heading {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 17.4px;
  display: block;
  -webkit-animation: moveToRight 1s ease-out;
          animation: moveToRight 1s ease-out;
  margin-bottom: 40px;
}

@-webkit-keyframes moveToLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveToLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveToRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveToRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveBtnBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes moveBtnBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.btn:link,
.btn:visited {
  text-decoration: none;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 100px;
  text-transform: uppercase;
  position: relative;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background-color: #fff;
  color: #777;
  font-weight: 600;
  font-size: 1rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  -webkit-animation: moveBtnBottom 0.5s ease-out 0.75s !important;
          animation: moveBtnBottom 0.5s ease-out 0.75s !important;
  -webkit-animation-fill-mode: backwards !important;
          animation-fill-mode: backwards !important;
}

.btn-white {
  background-color: #fff;
  color: #777;
  font-weight: 600;
  font-size: 1rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  -webkit-animation: moveBtnBottom 0.5s ease-out 0.75s !important;
          animation: moveBtnBottom 0.5s ease-out 0.75s !important;
  -webkit-animation-fill-mode: backwards !important;
          animation-fill-mode: backwards !important;
}

/* category games container Starts */
.categoriesGame {
  background-image: linear-gradient(to right bottom, #7e5ad4, #48d6be);
}

.homeGamesName {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #1febc6;
}

.gameInageWrapper {
  background-color: #fff;
  box-shadow: #244160 0px 1px 12px;
  border-radius: 10px;
  max-width: 300px;
}

.gameInageWrapper img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 200px;
}

.gameWrapperName {
  color: #232629cc;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 500;
}
/* category games container Ends */

/* login styling  starts */
.errorMsg {
  color: #b41717;
  font-weight: 500;
}

.agencyLoginBtn,
.agencyLoginBtn:hover,
.agencyLoginBtn:active {
  color: #495057 !important;
  font-size: 1rem;
  font-weight: 500 !important;
  border: 1px solid white !important;
  background-color: white !important;
  border-color: white !important;
}

.btn {
  font-weight: 500 !important;
}

/* login styling ends */

.extraSpace {
  padding-top: 70px;
}

.gameImg {
  max-width: 200px;
}

.c-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .primary-heading-main {
    font-size: 2rem;
    letter-spacing: 8px;
  }

  .text-box {
    width: 100%;
  }

  .primary-heading-sub-heading {
    font-size: 1rem;
    letter-spacing: 11.4px;
  }

  .gameInageWrapper {
    max-width: 200px;
  }

  .header {
    padding: 10px;
  }

  .extraSpace {
    padding-top: 55px;
  }

  .gameWrapperName {
    font-size: 1rem;
  }

  .gameInageWrapper img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 576px) {
  .gameInageWrapper {
    max-width: 140px;
  }

  .gameInageWrapper img {
    max-width: 100%;
  }
}

.footer-bar {
  text-align: center;
}

.footer-bar p {
  margin: 0;
  padding: 10px;
}

.footer-red-heart {
  max-width: 18px;
}
/* New CSS */

/* New CSS */

#mobileMenu {
  width: 100%;
  height: 100vh;
  left: -150vw;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transition: left ease-out 0.3s;
}

#mobileMenu .firstChild {
  width: 75%;
  height: 100vh;
}

#mobileMenu .secondChild {
  width: 25%;
  background-color: #000000;
  opacity: 0.4;
}

#mobileMenu .menuLink,
.mainSiteText {
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.4s;
  opacity: 0.9;
}

.showMobileMenu {
  left: 0 !important;
}

#mobileMenu .hamgurgerLink {
  color: #4f4d4d;
}

.mainLogo {
  max-width: 135px;
}

#mobileMenu .menuWrapper {
  border-radius: 20px;
  max-width: 300px;
}

#mobileMenu .menuWrapper:hover span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#mobileMenu .menuWrapper span {
  font-size: 1rem;
  font-weight: 600;
  color: #1febc6;
  letter-spacing: 0.05rem;
}

#mobileMenu .menuWrapper .menuIcon {
  font-size: 1rem;
  width: 45px;
  position: relative;
  top: -2px;
}

.followUsHeading {
  color: #ffce00;
  font-weight: 500;
  font-size: 1rem;
}

.mobileMenu_List__Active .menuWrapper {
  background-color: #eff6ff;
}

.mobileMenu_List__Active span,
.mobileMenu_List__Active .menuIcon {
  color: #051168 !important;
}

.crossMenu {
  max-width: 1.125rem;
}

.search-icon:hover {
  cursor: pointer;
}

.search-input {
  color: white;
  background-color: #383e6e;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  margin-top: 0.7rem;
}

.search-input::-webkit-input-placeholder {
  color: white;
}

.search-input:-ms-input-placeholder {
  color: white;
}

.search-input::-ms-input-placeholder {
  color: white;
}

.search-input::placeholder {
  color: white;
}

.search-input:active,
.search-input:focus {
  border: none;
  outline: none;
}

.loaderWrapper {
  width: 100%;
  height: 100vh;
  z-index: 30;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right bottom, #7e5ad4, #48d6be);
}

.lds-dual-ring {
  width: 50px;
  height: 50px;
  top: 45%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  border: 4px solid #fcad30;
  border-color: #fcad30 white white white;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 575px) {
  .lds-dual-ring {
    left: 50%;
  }
}

.Game_space__88ahK {
  padding: 2rem 0rem;
  display: inline-block;
  margin-right: 2rem;
}

.Game_container__OJVLu {
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  position: relative;
  z-index: 2;
  padding-right: 0.5rem;
}

.Game_background__16L0a {
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background: linear-gradient(
    56.69deg,
    rgba(254, 253, 251, 0.5) 2.41%,
    rgba(0, 118, 225, 0.5) 96.66%
  ); */
  -webkit-clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
}

.Game_play__3J053 {
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  width: 3rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;

  z-index: 2;
}

.Game_title__16_hS {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0;
  z-index: 2;
  padding-bottom: 1.4rem;
}
.Game_button__1HKB0 {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  z-index: 2;
}

.Game_game-logo__2WVmP {
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  z-index: 2;
}

.Game_button__1HKB0 {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.Game_button__1HKB0 img {
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  border-radius: 35%;
  width: 2rem;
}

.Game_right-buttons__HDEHk {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 0.2rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  top: 20%;
  right: 0.2rem;
  z-index: 5;
}

.Game_right-buttons__HDEHk img {
  width: 1.3rem;
  color: #fff;
}

.mainCarousel .wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.mainCarousel .wrapper1 {
  background: linear-gradient(90deg, #adcc26 0%, #67a6aa 100%);
}

.mainCarousel .wrapper2 {
  background: linear-gradient(90deg, #ed2226 0%, #ee7a55 100%);
}

.mainCarousel .wrapper3 {
  background: linear-gradient(90deg, #410a66 0%, #eb596a 100%);
}

.mainCarousel img {
  max-width: 100px;
}

.img {
  border-radius: 20px;
}

.mainCarousel .carouselTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.mainCarousel .carouselDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 11.33px;
  line-height: 13px;
  color: #ffffff;
  max-width: 210px;
}

.mainCarousel .carouselBtn {
  background: #afcfac;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 11.33px;
  line-height: 13px;
}

.carousel .control-dots {
  margin: 2px 0;
}

.carousel .control-dots .dot {
  margin: 0 3px;
  width: 7px;
  height: 7px;
}

.carousel .slide img {
  max-width: 96px;
}

@media only screen and (max-width: 768px) {
  .carousel .slide img {
    max-width: 76.8px;
  }
}

.trendingText {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #f8700e;
}

.trendingCard {
  background: #383e6e;
  border-radius: 5px;
  width: 75px;
  height: 90px;
  margin-right: 10px;
}
/* 
.trendingCard1,
.trendingCard2,
.trendingCard3 {
  margin-right: 10px;
} */

.trendingCard__Img1 {
  top: -20px;
  left: 13%;
  width: 3.5rem;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .trendingCard__Img1 {
    left: 18%;
  }
}

@media only screen and (max-width: 576px) {
  .trendingCard__Img1 {
    left: 20%;
  }
}

/* .trendingCard__Img2 {
  top: -22px;
  left: 8px;
}

.trendingCard__Img3 {
  top: -20px;
  left: 8px;
}

.trendingCard__Img4 {
  top: -25px;
  left: 8px;
} */

.trendingCard__Tittle {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: white;
}

.trendingCard__Number {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: white;
}

.Footer_footer__1qwcH {
  /* margin-top: 100%; */
  width: 100%;
  /* height: 20rem; */
  background-color: #202f47;

  z-index: 5;
  padding-top: 5rem;
  -webkit-clip-path: polygon(
    30% 4%,
    75% 24%,
    100% 15%,
    100% 65%,
    100% 100%,
    32% 100%,
    0 100%,
    0 60%,
    0 22%
  );
          clip-path: polygon(
    30% 4%,
    75% 24%,
    100% 15%,
    100% 65%,
    100% 100%,
    32% 100%,
    0 100%,
    0 60%,
    0 22%
  );
}

/* .footer::after {
  position: absolute;
  top: 3rem;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  clip-path: polygon(
    30% 1%,
    77% 19%,
    100% 10%,
    100% 65%,
    100% 100%,
    32% 100%,
    0 100%,
    0 60%,
    0 13%
  );

  background-color: #202f47;

  z-index: 10;
} */

.Footer_footer-container__N2ysL {
  /* padding-top: 4rem; */
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.Footer_logo-container__DMAPz {
  padding: 1rem 2rem;
  width: 50%;
}

.Footer_links__1xbbJ {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  justify-items: center;
}

.Footer_links__1xbbJ li,
.Footer_links__1xbbJ li a {
  font-size: 1.2rem;
  color: #1febc6;
  font-weight: 200;
}

.Footer_social-icons__2J0pN {
  width: 20%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 1.25rem;
}

.Footer_social-icons__2J0pN a {
  display: inline-block;
  width: 1.7rem;
  height: 2rem;
}

.Footer_footer-text__3k9kU {
  padding-bottom: 1rem;
}

.Footer_footer-text__3k9kU p {
  color: #1febc6;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .Footer_social-icons__2J0pN {
    width: 60%;
  }
}

.gameName {
  font-size: 1.5rem;
  color: #4f4d4d;
}

.sectionBg {
  background: rgba(32, 47, 71, 0.99);
}

.sectionBg .gameName {
  font-size: 1.25rem;
  color: #ffce00;
  font-weight: 500;
}

.sectionBg .moreLink {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f8700e;
}

.sectionBg .rightArrow {
  font-size: 0.75rem;
}

@media only screen and (max-width: 992px) {
  .sectionBg .gameName {
    font-size: 1rem;
  }

  .sectionBg .rightArrow {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 575px) {
  .gameName {
    font-size: 1.25rem;
  }
}

.categoryInfo {
  background-color: #f92c8b;
  font-size: 1.1rem;
  font-weight: 500;
}

.tableHeading {
  color: #20292f;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.3s;
}

.gameImage {
  max-width: 50px;
}

.fontIcon {
  font-size: 1rem;
}

.trend-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border: none;
  padding: 0.6rem;
}

.trend-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.trend-btn:active,
.trend-btn:focus {
  outline: none;
}

.trend-form {
  display: flex;
  flex-direction: column;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  padding: 2rem 0rem;
}

.trend-form label {
  margin-bottom: 0rem;
  color: #fff;
}

.trend-form input {
  padding: 0.3rem 0.6rem;
}

.trend-form input:focus,
.trend-form input:active {
  outline: none;
}

.trend-form button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.gameName {
  font-size: 1.5rem;
  color: #4f4d4d;
}

.sectionBg {
  background: rgba(32, 47, 71, 0.99);
}

.sectionBg .gameName {
  font-size: 1.25rem;
  color: #ffce00;
  font-weight: 500;
}

.sectionBg .moreLink {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f8700e;
}

.sectionBg .rightArrow {
  font-size: 0.75rem;
}

@media only screen and (max-width: 992px) {
  .sectionBg .gameName {
    font-size: 1rem;
  }

  .sectionBg .rightArrow {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 575px) {
  .gameName {
    font-size: 1.25rem;
  }
}

.leaderboardEntryWrapper {
  background-color: #eff6ff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0px #299595;
}

.leaderboardEntryWrapper span {
  font-weight: 500;
  color: #2c2c2c;
}

.leaderboardEntryWrapper .leaderboardEntryImage {
  max-width: 50px;
}

.leaderboardEntryWrapper .leaderboardEntryScore {
  font-size: 1.2rem;
}

.FooterMenuLogo {
  max-width: 20px;
}

.footer {
  background-color: #03032c;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}

.footerLinkColor {
  color: #fcad30;
  font-size: 0.75rem;
}

.footerLinkWithoutColor {
  color: #7b7b7b;
  font-size: 0.75rem;
}

iframe {
  width: 100%;
  height: calc(100vh - 58px);
}

.gamePlayMenus {
  background-color: #2e3888;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
}

/* IMA Styling */

.adLoadingWrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: none;
  z-index: 99987;
  background-color: #000;
}

.adLoadingWrapper span {
  display: block;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mainContainer {
  display: block;
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
}

#adContainer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-self: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  background: #000;
  z-index: -100;
}

#contentElement {
  margin: 0 auto;
  background: #000;
  position: absolute;
  top: 20%;
  z-index: -10;
}

.nonLinearContainer {
  bottom: 10;
  height: 100%;
}

#playButton {
  margin-top: 10px;
  vertical-align: top;
  width: 350px;
  height: 60px;
  padding: 0;
  font-size: 22px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #2c3e50;
  border: 0;
  border-bottom: 2px solid #22303f;
  cursor: pointer;
  box-shadow: inset 0 -2px #22303f;
}

#leaderboard {
  top: -150vh;
  left: 0;
  height: 100vh;
  transition: 0.4s;
}

#leaderboard header {
  padding: 3px 0;
}

#leaderboard .backLogo {
  max-width: 9px;
}

#leaderboard .topUsersWrapper {
  background-image: linear-gradient(180deg, #051a89, #081763);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 300px;
}

#leaderboard .userImageWrapper {
  width: 110px;
  height: 110px;
}

#leaderboard .arrowIcon {
  max-width: 15px;
}

#leaderboard .iconRankWrapper {
  top: -28px;
  left: 48px;
}

#leaderboard .topUsersWrapper .leaderUserImage {
  max-width: 110px;
  border: 14px solid #243cc3;
}

#leaderboard .userImageWrapper span,
#leaderboard .topUserRankWrapper span {
  line-height: 1;
  font-weight: 500;
}

#leaderboard .topUserWrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 180px;
}

#leaderboard .topUserImage {
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 130px;
}

#leaderboard .topUserCrown {
  top: 22%;
}

#leaderboard .topUserRankWrapper {
  top: 88%;
}

/* #leaderboard .userImage {
  max-width: 100px;
}

#leaderboard .scoreHeading {
  font-size: 1.35rem;
}

#leaderboard .scoreValue {
  font-size: 1.125rem;
}

#leaderboard .leaderboardEnteryImage {
  max-width: 40px;
}

#leaderboard .leaderboardEnteryRow1 {
  background-color: #2e3ba0;
}

#leaderboard .leaderboardEnteryRow2 {
  background-color: rgb(48 62 185);
}

#leaderboard .allUserRank,
#leaderboard .allUserName,
#leaderboard .allUserScore {
  font-size: 0.875rem;
} */

/* .FreeGameWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 30px;
}

.FreeGameWrapper::-webkit-scrollbar {
  height: 0px;
}

.FreeGameWrapper::-webkit-scrollbar-thumb {
  background-color: #3e51b5;
  border-radius: 10px;
}

.singleGameWrapper {
  border-radius: 20px;
  min-width: 175px;
  max-width: 175px;
}

.singleGameWrapper .gameImage {
  border-radius: 20px;
  min-width: 175px;
  max-width: 175px;
}

.singleGameWrapper .FreeGameWrapper__gameName {
  font-size: 1.25rem;
  font-weight: 500;
  color: black;
}

.singleGameWrapper .FreeGameWrapper__playNow {
  max-width: 25px;
  bottom: -10px;
}

.moreGamesIcon {
  left: 52%;
  top: 40%;
  font-size: 3.5rem;
  color: #3e51b5;
  transform: translate(-50%, -50%);
}

.GameCardBg1 {
  background: linear-gradient(
    56.95deg,
    rgba(255, 244, 119, 0.5) 2.33%,
    rgba(19, 71, 99, 0.5) 97.87%
  );
  border-radius: 10px;
}

.GameCard {
  width: 105px;
  height: 90px;
  top: 20px;
}

.GameCard__Img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 65px;
  height: 65px;
  top: -20px;
  left: 21px;
}

.GameCard__Title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  bottom: 15px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .singleGameWrapper {
    border-radius: 10px;
    min-width: 100px;
    max-width: 100px;
  }

  .singleGameWrapper .gameImage {
    border-radius: 10px;
    min-width: 100px;
    max-width: 100px;
  }

  .singleGameWrapper .FreeGameWrapper__gameName {
    font-size: 0.87rem;
  }

  .moreGamesIcon {
    font-size: 2.5rem;
  }
} */

/* /////////////////////// */

.game {
  -webkit-clip-path: polygon(0 49%, 100% 9%, 100% 100%, 0% 100%);
          clip-path: polygon(0 49%, 100% 9%, 100% 100%, 0% 100%);
  background-color: azure;
}

.DropDown_dropdown__1Ugr1 {
  background-color: #202f47;
  padding: 2rem 3rem;
  width: 70%;
  margin: 1rem auto;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 10% 1fr;
  align-items: start;
}

.DropDown_small-dropdown__gPepN {
  background-color: #202f47;
  padding: 2rem 3rem;
  width: 70%;
  margin: 1rem auto;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 10% 1fr;
}

.DropDown_image-container__1loR0 {
  width: 60%;
  height: auto;
}

.DropDown_image__2pfcC {
  width: 100%;
}

.DropDown_small-image-container__3fHKh {
  width: 30%;
}

.DropDown_rotate__3-6Pl {
  transform: rotate(180deg);
}

.DropDown_upIcon__2Izk5 {
  max-width: 1.25rem;
  transition: .25s linear all;
}

.DropDown_image-container__1loR0 img {
  width: 2.5rem;
}

.DropDown_category__3ZMyk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.DropDown_category__3ZMyk p {
  color: #1febc6;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.DropDown_text__11_D_ {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 1rem;
}

/* //////////////////// */

@media screen and (max-width: 768px) {
  .DropDown_dropdown__1Ugr1 {
    width: 90%;
  }

  .DropDown_small-dropdown__gPepN {
    width: 90%;
    grid-template-columns: 20% 1fr;
    padding: 2rem;
  }

  .DropDown_category__3ZMyk p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 550px) {
  html {
    font-size: 80%;
  }
  .DropDown_image-container__1loR0 {
    width: 100%;
  }
  .DropDown_dropdown__1Ugr1 {
    grid-template-columns: 20% 1fr;
    padding: 2rem 2rem;
  }
}

.AboutUs_container__1uqOA {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 0rem;
}

.AboutUs_about-us__3gUbF,
.AboutUs_about-us-text__SuOlO {
  color: #1febc6;
  font-size: 1.8rem;
  width: 90%;
  margin: 0 auto;
}

.AboutUs_about-us-text__SuOlO {
  color: #fff;
  font-size: 1.5rem;
}

.AboutUs_image-container__mpXK- {
  width: 100%;
  display: flex;
  justify-content: center;
}

.AboutUs_image-container__mpXK- img {
  max-width: 20rem;
}

.TermsPrivacy_container__NnwcU {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 0rem;
}

.TermsPrivacy_about-us__hdDDi,
.TermsPrivacy_about-us-text__fSvhz {
  color: #1febc6;
  font-size: 1.8rem;
  width: 90%;
  margin: 0 auto;
}

.TermsPrivacy_about-us-text__fSvhz {
  color: #fff;
  font-size: 1.5rem;
}
.TermsPrivacy_image-container__1C3OR {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TermsPrivacy_controller-image__1WFbQ {
  width: 60%;
}

.DropDown_dropdown__15K4j {
  background-color: #202f47;
  padding: 2rem 3rem;
  width: 70%;
  margin: 1rem auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.DropDown_image-container__3ajEr {
  width: 60%;
  height: auto;
}

.DropDown_image__3sKV9 {
  width: 100%;
}

.DropDown_small-image-container__hcueV {
  width: 30%;
}

.DropDown_rotate__U7oQ4 {
  transform: rotate(180deg);
}

.DropDown_image-container__3ajEr img {
  width: 100%;
}

.DropDown_category__2kSoi {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DropDown_category__2kSoi p {
  color: #1febc6;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.DropDown_text__1oGOn {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 1rem;
}

.DropDown_upIcon__2Q-k8 {
  max-width: 1.25rem;
  transition: 0.25s linear all;
}

/* //////////////////// */

@media screen and (max-width: 768px) {
  .DropDown_dropdown__15K4j {
    width: 90%;
  }
  .DropDown_small-dropdown__2Nz07 {
    width: 90%;
  }
  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 550px) {
  html {
    font-size: 80%;
  }
  .DropDown_image-container__3ajEr {
    width: 70%;
  }
  .DropDown_dropdown__15K4j {
    grid-template-columns: 40% 1fr;
    padding: 2rem 2rem;
  }
}

.backdrop {
  background-color: #fff;
  padding: 1rem;
  margin: 1rem 0rem;
  border-radius: 5px;
}

.form-element {
  margin-bottom: 1rem;
}

.form-element input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-element input:focus,
.form-element input:active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

