.trend-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border: none;
  padding: 0.6rem;
}

.trend-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.trend-btn:active,
.trend-btn:focus {
  outline: none;
}

.trend-form {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 2rem 0rem;
}

.trend-form label {
  margin-bottom: 0rem;
  color: #fff;
}

.trend-form input {
  padding: 0.3rem 0.6rem;
}

.trend-form input:focus,
.trend-form input:active {
  outline: none;
}

.trend-form button {
  width: fit-content;
}
