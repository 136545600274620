/* New CSS */

/* New CSS */

#mobileMenu {
  width: 100%;
  height: 100vh;
  left: -150vw;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transition: left ease-out 0.3s;
}

#mobileMenu .firstChild {
  width: 75%;
  height: 100vh;
}

#mobileMenu .secondChild {
  width: 25%;
  background-color: #000000;
  opacity: 0.4;
}

#mobileMenu .menuLink,
.mainSiteText {
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.4s;
  opacity: 0.9;
}

.showMobileMenu {
  left: 0 !important;
}

#mobileMenu .hamgurgerLink {
  color: #4f4d4d;
}

.mainLogo {
  max-width: 135px;
}

#mobileMenu .menuWrapper {
  border-radius: 20px;
  max-width: 300px;
}

#mobileMenu .menuWrapper:hover span {
  text-decoration-line: underline;
}

#mobileMenu .menuWrapper span {
  font-size: 1rem;
  font-weight: 600;
  color: #1febc6;
  letter-spacing: 0.05rem;
}

#mobileMenu .menuWrapper .menuIcon {
  font-size: 1rem;
  width: 45px;
  position: relative;
  top: -2px;
}

.followUsHeading {
  color: #ffce00;
  font-weight: 500;
  font-size: 1rem;
}

.mobileMenu_List__Active .menuWrapper {
  background-color: #eff6ff;
}

.mobileMenu_List__Active span,
.mobileMenu_List__Active .menuIcon {
  color: #051168 !important;
}

.crossMenu {
  max-width: 1.125rem;
}

.search-icon:hover {
  cursor: pointer;
}

.search-input {
  color: white;
  background-color: #383e6e;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  margin-top: 0.7rem;
}

.search-input::placeholder {
  color: white;
}

.search-input:active,
.search-input:focus {
  border: none;
  outline: none;
}
