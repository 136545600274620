.loaderWrapper {
  width: 100%;
  height: 100vh;
  z-index: 30;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right bottom, #7e5ad4, #48d6be);
}

.lds-dual-ring {
  width: 50px;
  height: 50px;
  top: 45%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  border: 4px solid #fcad30;
  border-color: #fcad30 white white white;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 575px) {
  .lds-dual-ring {
    left: 50%;
  }
}
