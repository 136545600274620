.footer {
  background-color: #03032c;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}

.footerLinkColor {
  color: #fcad30;
  font-size: 0.75rem;
}

.footerLinkWithoutColor {
  color: #7b7b7b;
  font-size: 0.75rem;
}
