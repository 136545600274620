.tableHeading {
  color: #20292f;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.3s;
}

.gameImage {
  max-width: 50px;
}

.fontIcon {
  font-size: 1rem;
}
