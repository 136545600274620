.footer {
  /* margin-top: 100%; */
  width: 100%;
  /* height: 20rem; */
  background-color: #202f47;

  z-index: 5;
  padding-top: 5rem;
  clip-path: polygon(
    30% 4%,
    75% 24%,
    100% 15%,
    100% 65%,
    100% 100%,
    32% 100%,
    0 100%,
    0 60%,
    0 22%
  );
}

/* .footer::after {
  position: absolute;
  top: 3rem;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  clip-path: polygon(
    30% 1%,
    77% 19%,
    100% 10%,
    100% 65%,
    100% 100%,
    32% 100%,
    0 100%,
    0 60%,
    0 13%
  );

  background-color: #202f47;

  z-index: 10;
} */

.footer-container {
  /* padding-top: 4rem; */
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.logo-container {
  padding: 1rem 2rem;
  width: 50%;
}

.links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  row-gap: 1rem;
  justify-items: center;
}

.links li,
.links li a {
  font-size: 1.2rem;
  color: #1febc6;
  font-weight: 200;
}

.social-icons {
  width: 20%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 1.25rem;
}

.social-icons a {
  display: inline-block;
  width: 1.7rem;
  height: 2rem;
}

.footer-text {
  padding-bottom: 1rem;
}

.footer-text p {
  color: #1febc6;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .social-icons {
    width: 60%;
  }
}
