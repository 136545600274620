.gameName {
  font-size: 1.5rem;
  color: #4f4d4d;
}

.sectionBg {
  background: rgba(32, 47, 71, 0.99);
}

.sectionBg .gameName {
  font-size: 1.25rem;
  color: #ffce00;
  font-weight: 500;
}

.sectionBg .moreLink {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f8700e;
}

.sectionBg .rightArrow {
  font-size: 0.75rem;
}

@media only screen and (max-width: 992px) {
  .sectionBg .gameName {
    font-size: 1rem;
  }

  .sectionBg .rightArrow {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 575px) {
  .gameName {
    font-size: 1.25rem;
  }
}
