.dropdown {
  background-color: #202f47;
  padding: 2rem 3rem;
  width: 70%;
  margin: 1rem auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 60%;
  height: auto;
}

.image {
  width: 100%;
}

.small-image-container {
  width: 30%;
}

.rotate {
  transform: rotate(180deg);
}

.image-container img {
  width: 100%;
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category p {
  color: #1febc6;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.text {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 1rem;
}

.upIcon {
  max-width: 1.25rem;
  transition: 0.25s linear all;
}

/* //////////////////// */

@media screen and (max-width: 768px) {
  .dropdown {
    width: 90%;
  }
  .small-dropdown {
    width: 90%;
  }
  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 550px) {
  html {
    font-size: 80%;
  }
  .image-container {
    width: 70%;
  }
  .dropdown {
    grid-template-columns: 40% 1fr;
    padding: 2rem 2rem;
  }
}
