body {
  font-family: "Roboto", sans-serif;
  background-color: #091529 !important;
}

.header {
  background: #202f47;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.navbarItems {
  list-style: none;
}

.navarLinksItems {
  display: inline-block;
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}

.header-box {
  height: 60vh;
  background-image: linear-gradient(to right bottom, #8d6fd5, #28b485);
  background-size: cover;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
}

.sectionBg {
  background-image: linear-gradient(to right bottom, #8d6fd5, #28b485);
}

.logo-box {
  position: absolute;
  top: 30px;
  left: 30px;
}

.logo {
  height: 45px;
}

.text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.primary-heading {
  color: #fff;
  text-transform: uppercase;
}

.primary-heading-main {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 22px;
  display: block;
  animation-name: moveToLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.primary-heading-sub-heading {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 17.4px;
  display: block;
  animation: moveToRight 1s ease-out;
  margin-bottom: 40px;
}

@keyframes moveToLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveToRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveBtnBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.btn:link,
.btn:visited {
  text-decoration: none;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 100px;
  text-transform: uppercase;
  position: relative;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background-color: #fff;
  color: #777;
  font-weight: 600;
  font-size: 1rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveBtnBottom 0.5s ease-out 0.75s !important;
  animation-fill-mode: backwards !important;
}

.btn-white {
  background-color: #fff;
  color: #777;
  font-weight: 600;
  font-size: 1rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveBtnBottom 0.5s ease-out 0.75s !important;
  animation-fill-mode: backwards !important;
}

/* category games container Starts */
.categoriesGame {
  background-image: linear-gradient(to right bottom, #7e5ad4, #48d6be);
}

.homeGamesName {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #1febc6;
}

.gameInageWrapper {
  background-color: #fff;
  box-shadow: #244160 0px 1px 12px;
  border-radius: 10px;
  max-width: 300px;
}

.gameInageWrapper img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 200px;
}

.gameWrapperName {
  color: #232629cc;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 500;
}
/* category games container Ends */

/* login styling  starts */
.errorMsg {
  color: #b41717;
  font-weight: 500;
}

.agencyLoginBtn,
.agencyLoginBtn:hover,
.agencyLoginBtn:active {
  color: #495057 !important;
  font-size: 1rem;
  font-weight: 500 !important;
  border: 1px solid white !important;
  background-color: white !important;
  border-color: white !important;
}

.btn {
  font-weight: 500 !important;
}

/* login styling ends */

.extraSpace {
  padding-top: 70px;
}

.gameImg {
  max-width: 200px;
}

.c-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .primary-heading-main {
    font-size: 2rem;
    letter-spacing: 8px;
  }

  .text-box {
    width: 100%;
  }

  .primary-heading-sub-heading {
    font-size: 1rem;
    letter-spacing: 11.4px;
  }

  .gameInageWrapper {
    max-width: 200px;
  }

  .header {
    padding: 10px;
  }

  .extraSpace {
    padding-top: 55px;
  }

  .gameWrapperName {
    font-size: 1rem;
  }

  .gameInageWrapper img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 576px) {
  .gameInageWrapper {
    max-width: 140px;
  }

  .gameInageWrapper img {
    max-width: 100%;
  }
}

.footer-bar {
  text-align: center;
}

.footer-bar p {
  margin: 0;
  padding: 10px;
}

.footer-red-heart {
  max-width: 18px;
}