.mainCarousel .wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.mainCarousel .wrapper1 {
  background: linear-gradient(90deg, #adcc26 0%, #67a6aa 100%);
}

.mainCarousel .wrapper2 {
  background: linear-gradient(90deg, #ed2226 0%, #ee7a55 100%);
}

.mainCarousel .wrapper3 {
  background: linear-gradient(90deg, #410a66 0%, #eb596a 100%);
}

.mainCarousel img {
  max-width: 100px;
}

.img {
  border-radius: 20px;
}

.mainCarousel .carouselTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.mainCarousel .carouselDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 11.33px;
  line-height: 13px;
  color: #ffffff;
  max-width: 210px;
}

.mainCarousel .carouselBtn {
  background: #afcfac;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 11.33px;
  line-height: 13px;
}

.carousel .control-dots {
  margin: 2px 0;
}

.carousel .control-dots .dot {
  margin: 0 3px;
  width: 7px;
  height: 7px;
}

.carousel .slide img {
  max-width: 96px;
}

@media only screen and (max-width: 768px) {
  .carousel .slide img {
    max-width: 76.8px;
  }
}
