.space {
  padding: 2rem 0rem;
  display: inline-block;
  margin-right: 2rem;
}

.container {
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 2;
  padding-right: 0.5rem;
}

.background {
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background: linear-gradient(
    56.69deg,
    rgba(254, 253, 251, 0.5) 2.41%,
    rgba(0, 118, 225, 0.5) 96.66%
  ); */
  clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
}

.play {
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  width: 3rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;

  z-index: 2;
}

.title {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0;
  z-index: 2;
  padding-bottom: 1.4rem;
}
.button {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  z-index: 2;
}

.game-logo {
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  z-index: 2;
}

.button {
  display: flex;
  gap: 0.5rem;
}

.button img {
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  border-radius: 35%;
  width: 2rem;
}

.right-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 0.2rem;
  gap: 0.5rem;
  top: 20%;
  right: 0.2rem;
  z-index: 5;
}

.right-buttons img {
  width: 1.3rem;
  color: #fff;
}
